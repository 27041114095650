import React from "react";
import Layout from "../components/Layout";
import data from "../../content/resources/sector_privado.json";
import {Link} from "gatsby";

export default function Privado() {
    return (
        <Layout>
            <div id="main">
                <div className="services_section_banner">
                    <div
                        className="services_banner"
                        style={{backgroundImage: `url(/../images/sectores/3.jpg)`}}
                    >
                        <h1>Privado</h1>
                    </div>
                </div>
                <div className="inner">
                    <div className="services_section_1">
                        <h1 className="services_section_1__title">Descripción</h1>
                        <p className="services_section_1__paragraph">
                            La sociedad del siglo XXI exige tomar decisiones estratégicas en un mercado incierto y en
                            constante cambio. Acceder a información relevante en el momento preciso representa una
                            ventaja competitiva frente a otras organizaciones que participan y diversos nichos de
                            mercado.
                            Habilitamos acceso a contenidos digitales y herramientas tecnológicas para la gestión del
                            conocimiento al interior de su empresa:
                        </p>
                        <ul style={{color: '#000000'}}>
                            <li>Soluciones de gestión documental</li>
                            <li>Vigilancia tecnológica</li>
                            <li>Solicitud de literatura científica y técnica bajo demanda</li>
                            <li>Suscripción a bases de datos científicas y revistas especializadas</li>
                            <li>Desarrollo de Chatbots con tecnología AI</li>
                        </ul>
                    </div>
                    <section className="tiles">
                        {data.map((service) => {
                            return (
                                <article key={service.id} className="style1">
                                    <span className="image">
                                        <img
                                            src={service.image}
                                            alt={service.alt}/>
                                    </span>
                                    <Link to={service.path} state={{service: service}}>
                                        <h2>{service.name}</h2>
                                        <div className="content">
                                            <p>
                                                {service.description}
                                            </p>
                                        </div>
                                    </Link>
                                </article>
                            )
                        })}
                    </section>
                </div>
            </div>
        </Layout>
    )
}